import { State } from "../state";
import { Request } from "@/network/request";
// import Swal from "sweetalert2";
export default class skilles {
  tableData = {
    data: [],
    headers: {
      id: {
        name: "id",
        keys: ["id"],
      },
      name: {
        name: "name",
        keys: ["name"],
      },
      description: {
        name: "description",
        keys: ["description"],
      },
    },
    meta: {
      total: 1,
      per_page: 1,
      current_page: 1,
    },
  };

  getState = new State();
  postState = new State();
  editState = new State();
  deleteState = new State();

  get(params) {
    let request = new Request({
      method: "get",
      endpoint: `/skill`,
      params: params,
      token: this.token,
    });

    this.getState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.tableData.data = value.skills;
          this.tableData.meta = value.meta;
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  post(payload) {
    let request = new Request({
      method: "post",
      endpoint: `/skill`,
      data: payload,
      token: this.token,
    });

    this.postState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.get();
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  edit(payload, id) {
    let request = new Request({
      method: "put",
      endpoint: `/skill/${id}`,
      data: payload,
    });

    this.editState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.get();
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
  delete(id) {
    let request = new Request({
      method: "delete",
      endpoint: `/skill/${id}`,
    });

    this.deleteState.trigger(
      async () => {
        return await request.send();
      },
      {
        onSuccess: (value) => {
          this.get();
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }
}

export const Skilles = new skilles();
