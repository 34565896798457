<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="dialog"
    @click:outside="$emit('closePopup')"
    @keydown.esc="$emit('closePopup')"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("add") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.name"
                outlined
                :label="$t('name')"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.description"
                outlined
                :label="$t('description')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closePopup')" color="blue darken-1" text>
          {{ $t("close") }}
        </v-btn>

        <v-btn
          :disabled="validate"
          :loading="loader"
          color="blue darken-1"
          text
          @click="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["dialog", "dialogData", "type", "loader"],
  data() {
    return {
      myDialog: false,
      data: {
        name: "",
        description: "",
      },
    };
  },
  methods: {
    submit() {
      if (this.type == "edit") {
        this.$emit("editData", { data: this.data, id: this.dialogData.id });
      } else {
        this.$emit("createData", this.data);
      }
    },
  },
  computed: {
    validate() {
      return this.data.name == "" || this.data.description == "";
    },
  },
  mounted() {
    if (this.type == "edit") {
      this.data.name = this.dialogData.name;
      this.data.description = this.dialogData.description;
      this.myDialog = this.dialog;
    }
  },
  // watch:{
  //   myDialog(val)
  // }
};
</script>
